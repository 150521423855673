import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../template/layout';
import Img from 'gatsby-image';
import Grid from './../template/grid';
import dateformat from 'dateformat';
import './article-item.scss';

const ArticleItem = ({ data }) => {
	const article = data.allMarkdownRemark.nodes[0].frontmatter;
	const formatDate = 	dateformat(article.date, 'dd.mm.yyyy');

	return (
		<Layout
			title={article.title}
			lang="fr"
			description={article.chapo}
			social={
				{
					image: article.featuredImage.childImageSharp.fixed,
					type: 'article',
					slug: `/news/${article.id}`
				}
			}
		>
			<div className="article-item-page">
				<Grid>
					<div className="article-details">
						<p className="date"> 
							Publié le <span className="date">{formatDate}</span>
						</p>
						<p className="author">
							Ecrit par
							<span className="type">{article.author}</span>
						</p>
					</div>
					<div className={`article-item ${article.id}`}>
						{article.title &&
							<h1>{article.title}</h1>
						}
						{article.chapo &&
							<h5 className="article-item-chapo" >{article.chapo}</h5>
						}
						<div className='article-item-body' dangerouslySetInnerHTML={{__html: `<div>${data.allMarkdownRemark.nodes[0].html}</div>`}}></div>
						{article.paragraphes &&
							article.paragraphes.map((p, key) => (
								<p key={key}>{p}</p>
							))
						}
						{article.marginalContent &&
						<div className="marginal-content-container">
							{article.marginalContent.map((e, i) => (
								<p key={i} className="marginal-content">{e}</p>
							))}
						</div>
						}

					</div>
				</Grid>
				<Img alt={article.altTextImage} fixed={article.featuredImage.childImageSharp.fixed} />
			</div>

		</Layout>
	);
};

export default ArticleItem;

export const query = graphql`
	query ArticleItem($slug: String){
		allMarkdownRemark(filter: {frontmatter: {id: {eq: $slug}}}){
			nodes {
				frontmatter{
					paragraphes
					chapo
					author
					categorie
					date
					id
					title
					type
					altTextImage
					marginalContent
					featuredImage {
						childImageSharp {
							fixed(width: 1920){
								src
							}
						}
					}
				}
            html
			}
		}
	}`;
